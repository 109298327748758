import styled from "styled-components";

interface IProps {
  show: boolean;
  onClose: Function;
  onDone: Function;
}

export const DeleteAccount = ({ show, onClose, onDone }: IProps) => {
  return (
    <>
      {show && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={show ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Title>
            Are you sure you want to delete your account?
          </Styled.Title>
          <Styled.Desc>
            All your data, including game progress, achievements, and purchases,
            will be permanently deleted.
          </Styled.Desc>

          <Styled.Button onClick={() => onDone()}>Delete account</Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};

const Styled = {
  Close: styled.div`
    position: absolute;
    z-index: 111;
    top: 16px;
    right: 16px;
    cursor: pointer;
    img {
      width: 24px;
    }
  `,
  Blur: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
  `,
  Sheet: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 102;
    text-align: center;

    display: none;
    &.show {
      display: block;
    }
  `,
  Content: styled.div`
    padding: 0 16px;
    padding-top: 32px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 0px -2px 0px 0px #f9b12e, 0px -16px 30px 8px #f9b12e4d;
    background: #1c1f24;
    padding-bottom: 16px;
  `,
  Icon: styled.div`
    text-align: center;
    padding: 16px 0;
    img {
      height: 137px;
      display: inline-block;
    }
  `,
  Title: styled.div`
    color: #ffffff;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  `,
  Desc: styled.div`
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 32px;
  `,

  Button: styled.div`
    height: 49px;
    padding: 14px 10px 14px 10px;
    gap: 4px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    font-size: 14px;
    font-weight: 500;
    color: white;
    justify-content: center;
    margin-bottom: 16px;
  `,
  Cancel: styled.div`
    height: 49px;
    padding: 14px 10px 14px 10px;
    gap: 4px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    background: #282b30;
    font-size: 14px;
    font-weight: 500;
    color: white;
    justify-content: center;
  `,
};
