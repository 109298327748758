import styled from "styled-components";
import { formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";

interface IProps {
  type: string;
  price: number;
  profit: number;
  onClose: Function;
  onDone: Function;
}

export const SheetAddBoost = ({
  type,
  onClose,
  onDone,
  profit,
  price,
}: IProps) => {
  return (
    <>
      {type && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={type ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            {type === "tap" && <img src="/img/tap.svg" />}
            {type === "energy" && <img src="/img/energy.svg" />}
          </Styled.Icon>
          <Styled.Title>
            {type === "tap" && "MultiTap"}
            {type === "energy" && "Max Energy"}
          </Styled.Title>

          {/* <Styled.Profit>
            {type === "multiTap" && <span>Profit per tap</span>}
            {type === "maxEnergy" && <span>Max energy</span>}+
            {formatMoneyDecimal(profit)}
          </Styled.Profit> */}

          <Styled.Coin>
            <img src="/img/coin.svg" />
            {formatMoneyDecimal(price)}
          </Styled.Coin>

          <Styled.Button onClick={() => onDone()}>Go ahead</Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};
