import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
const { adminApiUrl } = getConfig();

export const AddDaily = () => {
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState({
    day: 0,
    reward: 0,
    rewardDiamond: 0,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const getInitRank = () => {
    fetch(`${adminApiUrl}/admin/day-rewards`).then(async (response) => {
      const allDays = await response.json();

      if (allDays?.length) {
        setData({
          day: allDays[allDays.length - 1].day + 1,
          reward: 0,
          rewardDiamond: 0,
        });
      }
    });
  };

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        const response = await fetch(`${adminApiUrl}/admin/day-rewards/${id}`);
        const data = await response.json();
        setData(data);
      };
      fetchProduct();
    } else {
      getInitRank();
    }
  }, [id]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!data.day || !data.reward) {
      toast.error("Please fill all fields");
      return;
    }
    if (id) {
      await fetch(`${adminApiUrl}/admin/day-rewards/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      navigate(-1);
      return;
    }

    const response = await fetch(`${adminApiUrl}/admin/day-rewards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);

      setData({
        day: 0,
        reward: 0,
        rewardDiamond: 0,
      });
      getInitRank();
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <Styled.Wrap>
      <Styled.Label>Day</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.day}
        onChange={(e) =>
          setData({ ...data, day: parseInt(e.target.value) || 0 })
        }
      />
      <Styled.Label>Reward</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.reward}
        onChange={(e) =>
          setData({ ...data, reward: parseInt(e.target.value) || 0 })
        }
      />

      <Styled.Label>Reward Diamond</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.rewardDiamond}
        onChange={(e) =>
          setData({ ...data, rewardDiamond: parseInt(e.target.value) || 0 })
        }
      />

      <br />
      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
    font-size: 12px;
  `,
  Row: styled.div`
    display: flex;
    gap: 2px;
  `,
  RowItem: styled.div`
    flex: 2;
    &:first-child {
      flex: 1;
    }
  `,
};
