import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const { adminApiUrl } = getConfig();

const allLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export const AddBoost = () => {
  const { type } = useParams();
  const [data, setData] = useState({
    levels: allLevels.map((level) => ({
      level,
      price: (level + 1) * 100,
      profit: (level + 1) * 1,
    })),
  });

  useEffect(() => {
    if (type) {
      const fetchProduct = async () => {
        const response = await fetch(`${adminApiUrl}/admin/boosts/${type}`);
        const data = await response.json();
        setData(data);

        if (!data.id) {
          if (type == "tap") {
            setData({
              levels: allLevels.map((level) => ({
                level,
                price: (level + 1) * 10000,
                profit: (level + 1) * 1,
              })),
            });
          }
          if (type == "energy") {
            setData({
              levels: allLevels.map((level) => ({
                level,
                price: (level + 1) * 10000,
                profit: Math.round(1000 * (1 + level * 0.05)),
              })),
            });
          }
        }
      };
      fetchProduct();
    }
  }, [type]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!data.levels.length) {
      toast.error("Please fill all fields");
      return;
    }
    if (data.levels.some((level: any) => !level.price || !level.profit)) {
      toast.error("Please fill all fields");
      return;
    }
    await fetch(`${adminApiUrl}/admin/boosts/${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        ...data,
      }),
    });
    navigate(-1);

    setData({
      levels: allLevels.map((level) => ({
        level,
        price: (level + 1) * 1000,
        profit: (level + 1) * 100,
      })),
    });
  };

  return (
    <Styled.Wrap>
      {allLevels.map((level) => (
        <Styled.Row key={level}>
          <Styled.RowItem>
            <Styled.Label>Level</Styled.Label>
            <input
              type="number"
              value={level + 1}
              placeholder="Level"
              className="bg-slate-50 py-1 px-4 w-full outline-none"
            />
          </Styled.RowItem>
          <Styled.RowItem>
            <Styled.Label>Price</Styled.Label>
            <input
              placeholder="Price"
              className="bg-slate-50 py-1 px-4 w-full outline-none"
              value={data.levels.find((l: any) => l.level === level)?.price}
              onChange={(e) => {
                const newLevels = data.levels.map((l: any) =>
                  l.level === level
                    ? { ...l, level: Number(e.target.value) }
                    : l
                );
                setData({ ...data, levels: newLevels });
              }}
            />
          </Styled.RowItem>
          <Styled.RowItem>
            {type == "tap" && <Styled.Label>Profit per tap</Styled.Label>}
            {type == "energy" && <Styled.Label>Energy max</Styled.Label>}

            <input
              type="number"
              value={data.levels.find((l: any) => l.level === level)?.profit}
              placeholder="Profit"
              className="bg-slate-50 py-1 px-4 w-full outline-none"
              onChange={(e) => {
                const newLevels = data.levels.map((l: any) =>
                  l.level === level
                    ? { ...l, profit: Number(e.target.value) }
                    : l
                );
                setData({ ...data, levels: newLevels });
              }}
            />
          </Styled.RowItem>
        </Styled.Row>
      ))}

      <br />
      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
    font-size: 12px;
  `,
  Row: styled.div`
    display: flex;
    gap: 2px;
  `,
  RowItem: styled.div`
    flex: 2;
    &:first-child {
      flex: 1;
    }
  `,
};
