import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Product, User } from "../../../common/models";
import { Link } from "react-router-dom";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminBoost = () => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-center bg-slate-50 py-2 px-4">
        <img src={"/img/tap.svg"} className="w-10 h-10 mr-3" />
        <div className="flex flex-col justify-items-start w-4/5">
          <Link to={"/admin/boost/tap"}>
            <label className="font-bold">Multitap</label>
          </Link>
        </div>
      </div>

      <div className="flex flex-row items-center bg-slate-50 py-2 px-4">
        <img src={"/img/energy.svg"} className="w-10 h-10 mr-3" />
        <div className="flex flex-col justify-items-start w-4/5">
          <Link to={"/admin/boost/energy"}>
            <label className="font-bold">Max energy</label>
          </Link>
        </div>
      </div>
    </div>
  );
};
