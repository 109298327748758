import styled from "styled-components";
import { formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";

interface IProps {
  item: any;
  onClose: Function;
}

export const SheetInfo = ({ item, onClose }: IProps) => {
  return (
    <>
      {item && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={item ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            <img src={item?.icon} />
          </Styled.Icon>
          <Styled.Title>{item?.name}</Styled.Title>
          <Styled.Desc>{item?.description}</Styled.Desc>

          <br />

          <Styled.Cancel onClick={() => onClose()}>understood</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};
