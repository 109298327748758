import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = window.location;

  const menus = [
    {
      name: "Tasks",
      link: "/admin/tasks",
    },
    {
      name: "Users",
      link: "/admin/users",
    },
    // {
    //   name: "Settings",
    //   link: "/admin/settings",
    // },
    {
      name: "Product Diamond",
      link: "/admin/diamond",
    },
    {
      name: "Products",
      link: "/admin/products",
    },
    {
      name: "Boots",
      link: "/admin/boost",
    },
    // {
    //   name: "Categories",
    //   link: "/admin/categories",
    // },
    {
      name: "Ranks",
      link: "/admin/ranks",
    },
    {
      name: "Daily Bonus",
      link: "/admin/daily",
    },
    {
      name: "Shop",
      link: "/admin/bundles",
    },
  ];

  return (
    <Styled.Wrap>
      {
        <Styled.Tabs>
          {menus.map((menu) => (
            <Link to={menu.link} key={menu.link}>
              <Styled.Tab
                className={
                  pathname === menu.link ? "text-black" : "text-gray-400"
                }
              >
                {menu.name}
              </Styled.Tab>
            </Link>
          ))}
        </Styled.Tabs>
      }
      {children}
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div``,
  Tabs: styled.div`
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e5e7eb;
  `,
  Tab: styled.div`
    flex: 1;
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
  `,
  Main: styled.div``,
};
