import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";

const { adminApiUrl } = getConfig();

export const AddCategories = () => {
  const [data, setData] = useState({
    name: "",
  });

  const handleSubmit = async () => {
    await fetch(`${adminApiUrl}/admin/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    setData({
      name: "",
    });
  };

  return (
    <Styled.Wrap>
      <Styled.Label>Category name</Styled.Label>
      <input
        type="text"
        placeholder="Product name"
        value={data.name}
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />

      <br />
      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
  `,
};
