import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { Styled as SheetStyled } from "./SheetAddDiamond";
import { useWebSocket } from "../hooks/useWebsocket";
import { useEffect, useState, useCallback } from "react";
import { useUser } from "../hooks/useUser";
import { toast } from "react-toastify";
import { SheetBuyDiamondConfirm } from "./SheetBuyDiamondConfirm";
import { beginCell } from "@ton/core";
import { JettonMaster } from "@ton/ton";
import { useTonConnect } from "../hooks/useTonConnect";
import { useTonClient } from "../hooks/useTonClient";
import { JETTON_TRANSFER_GAS_FEES } from "../constants/fees.constants";
import {
  INVOICE_WALLET_ADDRESS,
  USDT_MASTER_ADDRESS,
} from "../constants/common-constants";
import { JettonWallet } from "../contracts/JettonWallet";
import { calculateUsdtAmount } from "../helpers/common-helpers";
import axios from "axios";

interface IProps {
  isShow: boolean;
  onClose: Function;
  onDone: Function;
}

export const SheetBuyDiamond = ({ isShow, onClose, onDone }: IProps) => {
  const [allBundle, setAllBundle] = useState<any[]>([]);
  const { webSocket } = useWebSocket();
  const { user } = useUser();

  const { connected, sender, walletAddress } = useTonConnect();
  const tonClient = useTonClient();

  const runBuy = (bundle: any) => {
    if (webSocket) {
      webSocket.emit("buyDiamond", {
        bundleId: bundle._id,
        tgUserId: user?.tgId,
      });
      toast.success("Send request buy diamond success");
    }
  };

  useEffect(() => {
    if (webSocket) {
      webSocket.emit("getBundles");
      webSocket.on("bundles", (data: any) => {
        setAllBundle(data);
      });
    }
    return () => {
      if (webSocket) {
        webSocket.off("bundles");
      }
    };
  }, [webSocket]);

  const [bundleSelect, setBundleSelect] = useState<any>(null);

  const handleCompletePaymentByUSDT = useCallback(
    async (userId: string, itemId: string, totalCost: number) => {
      try {
        if (!tonClient || !walletAddress) return;

        const jettonMaster = tonClient.open(
          JettonMaster.create(USDT_MASTER_ADDRESS)
        );
        const usersUsdtAddress = await jettonMaster.getWalletAddress(
          walletAddress
        );

        const jettonWallet = tonClient.open(
          JettonWallet.createFromAddress(usersUsdtAddress)
        );

        const forwardPayloadString = userId + ":" + itemId;
        const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail(forwardPayloadString)
          .endCell();

        const amount = calculateUsdtAmount(totalCost * 100);
        // console.log(amount)
        // console.log(forwardPayloadString)
        await jettonWallet.sendTransfer(sender, {
          fwdAmount: 1n,
          jettonAmount: amount, // usdt
          toAddress: INVOICE_WALLET_ADDRESS,
          value: JETTON_TRANSFER_GAS_FEES,
          forwardPayload: forwardPayload,
        });
        toast.success(
          "Send request buy diamond success, please waiting for confirm"
        );
        // console.log(`See transaction at https://tonviewer.com/${usersUsdtAddress.toString()}`);
      } catch (error) {
        // console.log('Error during transaction check:', error);
      }
    },
    [tonClient, walletAddress, sender]
  );

  return (
    <>
      {isShow && <SheetStyled.Blur onClick={() => onClose()} />}
      <SheetStyled.Sheet className={isShow ? "show" : ""}>
        <SheetStyled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </SheetStyled.Close>
        <SheetStyled.Content>
          <Styled.Title>
            Shop <span>$SPIN</span>
          </Styled.Title>
          <Styled.List>
            {allBundle.map((bundle) => {
              const level = Math.min(bundle.level, 6);
              return (
                <Styled.Item key={bundle._id}>
                  {bundle.bonus > 0 && (
                    <Styled.Bonus>
                      BONUS <br />
                      <span>{bundle.bonus}</span>
                    </Styled.Bonus>
                  )}
                  <Styled.Icon>
                    <img src={`/img/bundle${level}.png`} />
                  </Styled.Icon>
                  <Styled.Profit>
                    {formatMoney(bundle.profit)} <span>$SPIN</span>
                  </Styled.Profit>
                  <Styled.Button
                    onClick={() =>
                      setBundleSelect({
                        ...bundle,
                        level: level,
                      })
                    }
                  >
                    <span>${bundle.price}</span>
                  </Styled.Button>
                </Styled.Item>
              );
            })}
          </Styled.List>
        </SheetStyled.Content>
      </SheetStyled.Sheet>
      <SheetBuyDiamondConfirm
        bundle={bundleSelect}
        onClose={() => setBundleSelect(null)}
        onDone={async () => {
          // console.log(bundleSelect)
          const userId = user?.tgId.toString();
          const itemId = bundleSelect._id;
          const priceAmount = bundleSelect.price;

          // console.log(userId)
          // console.log(itemId)
          // console.log(priceAmount)
          if (userId && itemId && priceAmount) {
            // console.log("Go to payment wallet")
            await handleCompletePaymentByUSDT(userId, itemId, priceAmount);
          }

          // runBuy(bundleSelect);
          onClose();
          setBundleSelect(null);
        }}
      />
    </>
  );
};

const Styled = {
  Bonus: styled.div`
    height: 52px;
    width: 79px;
    position: absolute;
    top: -10px;
    left: 0;
    background-image: url("/img/bonus.svg");
    background-repeat: no-repeat;

    font-size: 14px;
    color: #fff;
    font-family: Quantico;
    font-weight: 700;
    line-height: 1;
    padding: 10px 0 0 0;
    span {
      font-size: 17px;
    }
  `,
  Title: styled.div`
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #777777;
    span {
      font-family: Quantico;
      color: #76f0fd;
      font-weight: 700;
    }
    gap: 4px;
    margin-bottom: 8px;
  `,
  List: styled.div`
    display: flex;
    max-height: 70vh;
    overflow-y: auto;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 18px;
  `,
  Item: styled.div`
    position: relative;
    width: calc(50% - 4px);
    background: #0f1e34;
    border-radius: 16px 16px 8px 8px;
    box-shadow: 0px 2px 1px 0px #00000099 inset;
    // height: 222px;
    padding: 0 8px 8px 8px;
  `,
  Icon: styled.div`
    width: 147px;
    height: 147px;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 16px 16px 0 0;
    }
  `,
  Profit: styled.div`
    color: #fff;
    margin-bottom: 8px;
    gap: 4px;
    font-family: Quantico;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #76f0fd;
      font-size: 16px;
    }
  `,
  Button: styled.div`
    height: 28px;

    background: linear-gradient(144.38deg, #ddfa6e 18.78%, #70bb00 79.54%);
    box-shadow: 0px 1px 1px 0px #ffffff99 inset;
    border: 0.4px solid #ffffff78;
    border-radius: 8px;
    padding: 2px;
    span {
      background: linear-gradient(144.38deg, #e1fb74 18.78%, #70bb00 79.54%);
      box-shadow: 0px 1px 1px 0px #ffffff3d inset, 0px 1px 1px 0px #64a202;

      display: block;
      font-size: 14px;
      font-weight: 600;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #446c04;
      border-radius: 7px;
    }
  `,
};
