import React from "react";
import styled from "styled-components";

export const ClickerSkeleton: React.FC = () => {
  return (
    <Styled.Wrap>
      <Styled.Content>xxx</Styled.Content>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div``,
  Content: styled.div``,
};
