import React from "react";
import { useUser } from "../../hooks/useUser";
import { ReferralLink } from "../../components/ReferralLink";
import styled from "styled-components";

export const Airdrop = () => {
  return (
    <Styled.Wrap>
      <Styled.Title>Airdrop Tasks</Styled.Title>
      <Styled.Desc>
        Listing is on its way. Tasks will appear below. Complete them to
        particvipate in the Airdrop
      </Styled.Desc>
      <Styled.Image>
        <img src="/img/airdrop.png" />
      </Styled.Image>
      <Styled.ButtonConnect>Connect your MetaMask Wallet</Styled.ButtonConnect>
    </Styled.Wrap>
  );
};

const Styled = {
  Image: styled.div`
    padding: 16px;
    margin-bottom: 48px;
    img {
      display: block;
      margin: 0 auto;
      height: 377px;
    }
  `,
  Wrap: styled.div`
    height: 100%;
    overflow: auto;
    background-image: url("/img/bg-2.jpg");
    padding: 16px;
    padding-top: 32px;
  `,
  Title: styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 48px;
  `,
  Desc: styled.div`
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  `,

  ButtonConnect: styled.div`
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    border-radius: 8px;
    color: white;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 14px;
  `,
};
