import styled from "styled-components";
import { formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";
import { useWebSocket } from "../hooks/useWebsocket";
import { useUser } from "../hooks/useUser";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface IProps {
  isOpen: boolean;
  onClose: Function;
}

export const SheetCommucationReward = ({ isOpen, onClose }: IProps) => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [commucationTasks, setCommucationTasks] = useState<any[]>([]);

  useEffect(() => {
    if (!webSocket || !isOpen) return;
    webSocket.emit("getCommucationTasks");
    webSocket.on("commucationTasks", (l) => {
      setCommucationTasks(l);
    });
    return () => {
      webSocket?.off("commucationTasks");
    };
  }, [webSocket, isOpen]);

  const handleCheckStatus = (task?: any) => {
    const tgUserId = user?.tgId;
    webSocket?.emit("checkTaskStatus", JSON.stringify([tgUserId, task]));
  };

  useEffect(() => {
    commucationTasks
      .filter((a) => !a.isCompleted)
      .map((a: any, index) => {
        setTimeout(() => {
          handleCheckStatus(a);
        }, index * 1000);
      });
  }, [commucationTasks]);

  commucationTasks.map((a: any) => {
    a.isCompleted = (user?.taskFinished || []).some((t) => t.type == a.type);
  });

  return (
    <>
      {isOpen && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={isOpen ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <br />
          <Styled.Title>Communication reward</Styled.Title>
          <Styled.Desc>Complete the task and earn more coins!</Styled.Desc>
          <br />
          <S.List>
            {commucationTasks.map((task: any) => (
              <S.Item
                key={task.id}
                className={task.isCompleted ? "claimed" : ""}
              >
                <S.ItemTitle>{task?.name}</S.ItemTitle>
                {task.isCompleted && <S.Complete>Completed</S.Complete>}
                <S.ItemReward>
                  <img src="/img/coin.svg" />+
                  {formatMoneyDecimal(task.rewardAmount)}
                </S.ItemReward>
                <S.Reward>
                  <img src="/img/dinamod.svg" />+
                  {formatMoneyDecimal(task.rewardDiamond)}
                </S.Reward>
              </S.Item>
            ))}
          </S.List>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};

const S = {
  List: styled.div`
    color: white;
  `,
  Complete: styled.div`
    background: #077812ff;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 10px;

    animation: zoomIn 0.3s ease-in-out;

    @keyframes zoomIn {
      from {
        transform: scale(0.5);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }
  `,
  Item: styled.div`
    display: flex;
    background: #282b30;
    box-shadow: 0px -4px 0px 0px #6161fb;
    border-radius: 12px;
    border: 0.3px solid #787878;
    padding: 10px;
    margin-bottom: 17px;

    &.claimed {
      // opacity: 0.5;
    }
  `,
  ItemTitle: styled.div`
    flex: 1;
    text-align: left;
  `,
  ItemImage: styled.div`
    img {
      width: 50px;
    }
  `,
  ItemReward: styled.div`
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
    margin-right: 10px;
  `,
  Reward: styled.div`
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
  `,
};
