export const formatMoney = (amount = 0) => {
    return amount.toLocaleString('en-US')
}

export const formatMoneyDecimal = (amount = 0) => {

    if (amount >= 1000) {
        return formatMoney(Math.floor(amount / 10) / 100) + 'K'
    }
    if (amount >= 1000000) {
        return formatMoney(Math.floor(amount / 10000) / 100) + 'M'
    }
    if (amount >= 1000000000) {
        return formatMoney(Math.floor(amount / 10000000) / 100) + 'B'
    }
    return formatMoney(amount)

}
