import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Product, User } from "../../../common/models";
import { Link } from "react-router-dom";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminDiamond = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [balanceSort, setBalanceSort] = useState<"asc" | "desc">("desc");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const refreshUsers = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/products-diamond?skip=${skip}&take=${TAKE}&balanceSort=${balanceSort}`
    );
    const data = await response.json();
    setProducts(data.data);
    setTotal(data.total);
  };

  useEffect(() => {
    refreshUsers();
  }, [page, balanceSort]);

  const onNextClick = () => {
    if (page < Math.ceil(total / TAKE)) {
      setPage(page + 1);
    }
  };

  const onPrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onSortClick = () => {
    if (balanceSort === "asc") {
      setBalanceSort("desc");
    } else {
      setBalanceSort("asc");
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-start justify-between">
        <button
          className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
          onClick={onSortClick}
        >
          Balance sort: {balanceSort === "asc" ? "asc" : "desc"}
        </button>

        <div className="flex flex-row items-center space-x-2">
          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onPrevClick}
          >
            Prev
          </button>

          <span className="py-2 px-4 bg-slate-50 font-mono text-sm">
            {(page - 1) * TAKE + 1} - {(page - 1) * TAKE + products.length}
          </span>

          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onNextClick}
          >
            Next
          </button>
        </div>
      </div>

      <Link
        to="/admin/diamond/add"
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono text-center"
      >
        ADD PRODUCT
      </Link>

      <div className="flex flex-col space-y-2">
        {products.map((product, index) => (
          <div
            className="flex flex-row items-center bg-slate-50 py-2 px-4"
            key={product.id}
          >
            {product.type == "x2_coin" && (
              <img src="/img/x2coin.png" className="w-10 h-10 mr-3" />
            )}
            {product.type == "100_crit" && (
              <img src="/img/critx100.png" className="w-10 h-10 mr-3" />
            )}
            {product.type == "12h_off" && (
              <img src="/img/off12h.png" className="w-10 h-10 mr-3" />
            )}

            <div className="flex flex-col justify-items-start w-4/5">
              <Link to={"/admin/diamond/" + product.id}>
                <label className="font-bold">{product.name}</label>
              </Link>

              <label>Max buy per week: {product.maxBuyPerWeek} times</label>
              <label className="">Price: {product.price}</label>
            </div>

            {product.active ? (
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-light py-1 px-4   font-mono"
                onClick={async () => {
                  await fetch(
                    `${adminApiUrl}/admin/products-diamond/${product.id}/deactivate`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  refreshUsers();
                }}
              >
                Deactive
              </button>
            ) : (
              <button
                className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4   font-mono"
                onClick={async () => {
                  await fetch(
                    `${adminApiUrl}/admin/products-diamond/${product.id}/activate`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  refreshUsers();
                }}
              >
                Active
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
