import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
const { adminApiUrl } = getConfig();

export const AddRank = () => {
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState({
    name: "",
    key: "",
    min: 0,
    max: 0,
    level: 1,
    rechargeEnergy: 0,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const getInitRank = () => {
    fetch(`${adminApiUrl}/admin/ranks`).then(async (response) => {
      const allRanks = await response.json();

      if (allRanks?.length) {
        setData({
          min: allRanks[allRanks.length - 1].max + 1,
          max: allRanks[allRanks.length - 1].max + 10000,
          name: "Rank " + (allRanks.length + 1),
          key: "rank_" + (allRanks.length + 1),
          level: allRanks.length + 1,

          rechargeEnergy: allRanks[allRanks.length - 1].rechargeEnergy + 1,
        });
      }
    });
  };

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        const response = await fetch(`${adminApiUrl}/admin/ranks/${id}`);
        const data = await response.json();
        setData(data);
      };
      fetchProduct();
    } else {
      getInitRank();
    }
  }, [id]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!data.name || !data.max) {
      toast.error("Please fill all fields");
      return;
    }
    if (data.min > data.max) {
      toast.error("Min must be less than max");
      return;
    }
    if (id) {
      await fetch(`${adminApiUrl}/admin/ranks/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      navigate(-1);
      return;
    }

    const response = await fetch(`${adminApiUrl}/admin/ranks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);

      setData({
        name: "",
        key: "",
        min: 0,
        max: 0,
        level: 0,
        rechargeEnergy: 0,
      });
      getInitRank();
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <Styled.Wrap>
      <Styled.Label>Level</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.level}
        onChange={(e) => setData({ ...data, level: parseInt(e.target.value)  || 0})}
      />
      <Styled.Label>Rank name</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />

      <Styled.Label>Rank key</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.key}
        onChange={(e) => setData({ ...data, key: e.target.value })}
      />

      <Styled.Label>Min</Styled.Label>
      <input
        type="number"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.min}
        onChange={(e) => setData({ ...data, min: parseInt(e.target.value)  || 0})}
      />
      <Styled.Label>Max</Styled.Label>
      <input
        type="number"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.max}
        onChange={(e) => setData({ ...data, max: parseInt(e.target.value)  || 0})}
      />
      <br />

      <Styled.Label>rechargeEnergy</Styled.Label>
      <input
        type="number"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.rechargeEnergy}
        onChange={(e) =>
          setData({ ...data, rechargeEnergy: parseInt(e.target.value)  || 0})
        }
      />

      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
    font-size: 12px;
  `,
  Row: styled.div`
    display: flex;
    gap: 2px;
  `,
  RowItem: styled.div`
    flex: 2;
    &:first-child {
      flex: 1;
    }
  `,
};
