import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Product, User } from "../../../common/models";
import { Link } from "react-router-dom";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminDaily = () => {
  const [products, setProducts] = useState<any[]>([]);
  const refreshUsers = async () => {
    const response = await fetch(`${adminApiUrl}/admin/day-rewards`);
    const data = await response.json();
    setProducts(data);
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      <Link
        to="/admin/daily/add"
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono text-center"
      >
        ADD DAY
      </Link>

      <div className="flex flex-col space-y-2">
        {products.map((product, index) => (
          <div
            className="flex flex-row items-center bg-slate-50 py-2 px-4"
            key={product.id}
          >
            <img src={product.icon} className="w-10 h-10 mr-3" />
            <div className="flex flex-col justify-items-start w-4/5">
              <Link to={"/admin/daily/" + product.id}>
                <label className="font-bold">
                  Day: {product.day}
                  <br />
                  Profit: {product.reward}
                  <br />

                  Diamonds: {product.rewardDiamond}
                </label>
              </Link>
            </div>
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-light py-1 px-4 w-1/5 font-mono"
              onClick={async () => {
                await fetch(`${adminApiUrl}/admin/day-rewards/${product.id}`, {
                  method: "DELETE",
                });
                refreshUsers();
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
