import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Product, User } from "../../../common/models";
import { Link } from "react-router-dom";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminCategories = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [balanceSort, setBalanceSort] = useState<"asc" | "desc">("desc");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const refreshUsers = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/categories?skip=${skip}&take=${TAKE}&balanceSort=${balanceSort}`
    );
    const data = await response.json();
    setProducts(data);
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      <Link
        to="/admin/categories/add"
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono text-center"
      >
        ADD CATEGORY
      </Link>

      <div className="flex flex-col space-y-2">
        {products.map((product, index) => (
          <div
            className="flex flex-row items-center bg-slate-50 py-2 px-4"
            key={product.id}
          >
            <div className="flex flex-col justify-items-start w-3/5">
              <label className="font-bold">{product.name}</label>
            </div>
            {product.active ? (
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-light py-1 px-4   font-mono"
                onClick={async () => {
                  await fetch(
                    `${adminApiUrl}/admin/categories/deactivate/${product.id}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  refreshUsers();
                }}
              >
                Deactive
              </button>
            ) : (
              <button
                className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4   font-mono"
                onClick={async () => {
                  await fetch(
                    `${adminApiUrl}/admin/categories/activate/${product.id}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  refreshUsers();
                }}
              >
                Active
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
