import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DeleteAccount } from "../components/DeleteAccount";
import { useState } from "react";
import {TonConnectButton} from "@tonconnect/ui-react";

export const Settings = () => {
  const nvaigate = useNavigate();
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      <Styled.Wrap>
        <Styled.Header>
          <Styled.Back onClick={() => nvaigate(-1)}>
            <img src="/img/back.svg" alt="back" />
          </Styled.Back>
          <Styled.Title>Settings</Styled.Title>
          <Styled.Action></Styled.Action>
        </Styled.Header>

        <Styled.Content>
          <Styled.Item>
            <Styled.ItemName>Select language</Styled.ItemName>
            <Styled.ItemDesc>English</Styled.ItemDesc>
            <img src="/img/right.svg" alt="arrow" />
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemName>Select Exchanger</Styled.ItemName>
            <Styled.ItemDesc>Binance</Styled.ItemDesc>
            <img src="/img/right.svg" alt="arrow" />
          </Styled.Item>
          <Styled.Item onClick={() => setIsDelete(true)}>
            <Styled.ItemName>Delete Account</Styled.ItemName>
            {/* <Styled.ItemDesc>Binance</Styled.ItemDesc> */}
            <img src="/img/right.svg" alt="arrow" />
          </Styled.Item>

          <Styled.ButtonConnect>
            {/*Connect your MetaMask Wallet*/}
            <TonConnectButton/>
          </Styled.ButtonConnect>
        </Styled.Content>
      </Styled.Wrap>

      <DeleteAccount
        show={isDelete}
        onClose={() => setIsDelete(false)}
        onDone={() => {
          setIsDelete(false);
          nvaigate(-1);
        }}
      />
    </>
  );
};

const Styled = {
  ButtonConnect: styled.div`
    // background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    border-radius: 8px;
    color: white;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 14px;
  `,
  Wrap: styled.div`
    background-color: #010101;
    height: 100%;
    color: white;
  `,
  Content: styled.div`
    padding: 16px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    align-items: center;
  `,
  Back: styled.div`
    width: 24px;
  `,
  Action: styled.div`
    width: 24px;
  `,
  Title: styled.div`
    font-size: 20px;
    font-weight: 500;
  `,

  Item: styled.div`
    background-color: #282b30;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  ItemIcon: styled.div`
    img {
      height: 30px;
    }
  `,
  ItemName: styled.div`
    flex: 1;
  `,
  ItemDesc: styled.div`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #777777;
    img {
      height: 20px;
    }
  `,
};
