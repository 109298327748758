import React, { useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { ReferralLink } from "../../components/ReferralLink";
import styled from "styled-components";
import { getReferralLink, getShareLink } from "../../services/telegramService";
import { toast } from "react-toastify";
import { useWebSocket } from "../../hooks/useWebsocket";
import { User } from "../../common/models";

export const Friend = () => {
  const { user, setUser } = useUser();
  const referrals = user?.referrals;

  const [tabActive, setTabActive] = React.useState(1);

  const [link, setLink] = useState("");

  useEffect(() => {
    const fetchLink = async () => {
      const referralLink = await getReferralLink();
      setLink(referralLink);
    };

    fetchLink();
  }, []);

  const handleShare = () => {
    const shareLink = getShareLink();
    window.open(shareLink, "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        toast.success("Link copied to clipboard!");
      },
      (error) => {
        console.error("Error copying to clipboard", error);
      }
    );
  };

  const [allInvites, setAllInvites] = useState<
    {
      tier: number;
      invites: User[];
    }[]
  >([]);

  const { webSocket } = useWebSocket();
  useEffect(() => {
    if (webSocket && user?.tgId) {
      webSocket.emit("getInvites", user?.tgId);

      webSocket.on("invites", (data) => {
        setAllInvites(data);
      });

      return () => {
        webSocket?.off("referrals");
      };
    }
  }, [webSocket, user]);

  const inviteList =
    allInvites.find((item) => item.tier === tabActive)?.invites || [];

  return (
    <Styled.Wrap>
      <Styled.Title>Invite friends!</Styled.Title>
      <Styled.Desc>You and your friend will receive bonuses</Styled.Desc>

      <Styled.TaskWrap>
        <Styled.Task>
          <Styled.TaskMain>
            <Styled.TaskIcon>
              <img src="/img/chest1.svg" />
            </Styled.TaskIcon>
            <Styled.TaskContent className="haveborder">
              <Styled.TaskTitle>Invite a friend</Styled.TaskTitle>
              <Styled.TaskDesc>
                <img src="/img/coin.svg" />
                <strong> +5,000</strong>
                For you & your friend
              </Styled.TaskDesc>
            </Styled.TaskContent>
          </Styled.TaskMain>
          <Styled.TaskMain>
            <Styled.TaskIcon>
              <img src="/img/chest2.svg" />
            </Styled.TaskIcon>
            <Styled.TaskContent>
              <Styled.TaskTitle>Invite a friend with Premium</Styled.TaskTitle>
              <Styled.TaskDesc>
                <img src="/img/coin.svg" />
                <strong> +25,000</strong>
                <img src="/img/dinamod.svg" />
                <strong> +100</strong>
              </Styled.TaskDesc>
              <Styled.TaskDesc>For you & your friend</Styled.TaskDesc>
            </Styled.TaskContent>
          </Styled.TaskMain>
        </Styled.Task>
      </Styled.TaskWrap>

      <Styled.Friends>
        <Styled.FriendHeader>
          <Styled.SubTitle>Profit Sharing</Styled.SubTitle>

          <Styled.Button>Receive all</Styled.Button>
        </Styled.FriendHeader>
        <Styled.Tabs>
          {allInvites.map((item) => (
            <Styled.Tab
              className={tabActive === item.tier ? "active" : ""}
              onClick={() => setTabActive(item.tier)}
            >
              Tier {item.tier}
            </Styled.Tab>
          ))}
        </Styled.Tabs>

        {inviteList.map((item) => {
          let name = [item.firstName, item.lastName].filter((a) => a).join(" ");
          if (!item.firstName && !item.lastName) {
            name = item.tgUsername;
          }
          return (
            <Styled.Item key={item.tgId}>
              <Styled.ItemAvatar>
                <img src="/img/logo.svg" />
              </Styled.ItemAvatar>
              <Styled.ItemInfo>
                <Styled.ItemName>
                  {name}
                </Styled.ItemName>
                <Styled.ItemNote>{item.totalBalance}</Styled.ItemNote>
              </Styled.ItemInfo>
              {item.profitInvite > 0 && (
                <Styled.Button>
                  <img src="/img/dinamod.svg" />
                  {item.profitInvite}
                </Styled.Button>
              )}
            </Styled.Item>
          );
        })}
      </Styled.Friends>

      <Styled.Invite>
        <Styled.InviteButton onClick={handleShare}>
          Invite your friend
        </Styled.InviteButton>
        <Styled.CopyButton onClick={handleCopy}>
          <img src="/img/copy.svg" />
        </Styled.CopyButton>
      </Styled.Invite>
    </Styled.Wrap>
  );
};

const Styled = {
  InviteButton: styled.div`
    flex: 1;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4176ff;

    font-size: 14px;

    gap: 4px;
    border-radius: 8px;

    text-shadow: 0px 1px 0px 0px #00000040;
    color: #fff;
    font-weight: 500;

    @keyframes ZOOM {
      0% {
        transform: scale(0.95);
      }
      60% {
        transform: scale(1.01);
      }
      100% {
        transform: scale(0.95);
      }
    }

    animation: ZOOM 2s infinite;
  `,
  CopyButton: styled.div`
    height: 48px;
    align-items: center;
    justify-content: center;
    width: 48px;
    display: flex;
    border-radius: 8px;
    background: #4176ff;
  `,
  Invite: styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    position: fixed;
    bottom: 68px;
    left: 8px;
    right: 8px;
  `,
  FriendHeader: styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  `,
  Button: styled.div`
    height: 28px;

    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    border: 0.5px solid;

    border-image-source: linear-gradient(
      100.78deg,
      rgba(229, 229, 255, 0.5) 0%,
      rgba(90, 225, 255, 0.5) 100%
    );
    box-shadow: 0px -2px 0px 0px #00000040 inset;

    color: #fff;
    font-size: 14px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
  `,
  Friends: styled.div`
    padding: 0 16px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 0px -2px 0px 0px #8515de, 0px -16px 30px 0px #0b167680;

    background: #1c1f24;
    padding-bottom: 16px;
    min-height: 500px;
    padding-bottom: 120px;
  `,
  Tabs: styled.div`
    display: flex;
    gap: 16px;
    border-bottom: 0.5px solid #777777;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Tab: styled.div`
    font-size: 16px;
    line-height: 16px;
    color: #777777;
    height: 32px;
    position: relative;
    &.active {
      color: white;
      span {
        background: -webkit-linear-gradient(#6161fb 0%, #b15aff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
      }
    }
  `,

  Item: styled.div`
    background: #282b30;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    color: #fff;
    align-items: center;
    gap: 8px;
  `,
  ItemAvatar: styled.div`
    height: 60px;
    width: 60px;
    background: #1e1e1e;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 40px;
    }
  `,
  ItemInfo: styled.div`
    flex: 1;
  `,
  ItemName: styled.div`
    font-size: 18px;
  `,
  ItemNote: styled.div`
    color: #8e8e8e;
    font-size: 12px;
  `,
  ItemProfit: styled.div`
    display: flex;
    font-size: 14px;
    gap: 4px;
    img {
      height: 20px;
      width: 20px;
    }
  `,

  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    color: #fff;
    margin-bottom: 16px;
    font-weight: 500;
  `,
  SubTitle: styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  `,
  Wrap: styled.div`
    height: 100%;
    overflow: auto;
    background-image: url("/img/bg-2.jpg");
    // padding: 16px;
    padding-top: 32px;
  `,
  Content: styled.div``,
  Title: styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 48px;
  `,
  Desc: styled.div`
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  `,

  TaskWrap: styled.div`
    padding: 16px;
    margin-bottom: 16px;
  `,
  Task: styled.div`
    background: linear-gradient(180deg, #6262fb 0%, rgba(177, 91, 255, 0) 100%);

    border-radius: 16px;
    // padding: 16px;
  `,
  TaskMain: styled.div`
    display: flex;
    gap: 16px;
    padding: 16px;
    // margin-bottom: 16px;
  `,
  TaskContent: styled.div`
    &.haveborder {
      border-bottom: 1px dashed #030303;
    }
  `,
  TaskTitle: styled.div`
    font-size: 16px;
    color: #fff;
    line-height: 24px;
  `,
  TaskDesc: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    line-height: 24px;
    color: #fff;

    strong {
      color: #ffde50;
      font-size: 16px;
      font-weight: 500;
    }
  `,
  TaskIcon: styled.div`
    img {
      width: 72px;
      height: 72px;
    }
  `,

  TaskButton: styled.div`
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    height: 37px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    color: #fff;
    padding: 8px 10px 8px 10px;
  `,
};
