import React from "react";
import QRCode from "react-qr-code";
import { getConfig } from "../utils/config";

const QRCodeComponent = () => {
  const config = getConfig();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#001f3f",
      }}
    >
      <QRCode
        value={config.tgBotLink}
        bgColor="#001f3f"
        fgColor="#ffffff"
        size={256}
      />
      <div className="mt-2 color-white text-white">
        Scan QR and open app on your phone
      </div>
    </div>
  );
};

export default QRCodeComponent;
