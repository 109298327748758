import React from "react";
import styled from "styled-components";

const MaintenanceNotice: React.FC = () => {
  return (
    <Styled.Wrap>
      <Styled.Title>Under Maintenance</Styled.Title>
      <Styled.Desc>
        Sorry for the temporary inconvenience, it will be fixed soon
      </Styled.Desc>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    padding-top: 0;
    background: #000;
  `,

  Title: styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 48px;
    color: #fff;
  `,
  Desc: styled.div`
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
    color: #fff;
  `,
};

export default MaintenanceNotice;
