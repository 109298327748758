import styled from "styled-components";
import { formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";
import { useWebSocket } from "../hooks/useWebsocket";
import { useUser } from "../hooks/useUser";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface IProps {
  isOpen: boolean;
  onClose: Function;
}

export const SheetDailyReward = ({ isOpen, onClose }: IProps) => {
  const [days, setDays] = useState<any[]>([]);
  const { webSocket } = useWebSocket();
  const { user } = useUser();

  const addRewardDay = () => {
    if (webSocket) {
      webSocket.emit(
        "activateBoost",
        JSON.stringify([user?.tgId, "dailyReward"])
      );
    }
  };

  useEffect(() => {
    if (webSocket) {
      webSocket.emit("getRewards");
      webSocket.on("rewards", (data: any) => {
        setDays(data);
      });
      webSocket.on("boostActivated", (data: any) => {
        toast.success(data);
      });
      webSocket.on("boostActivateWarning", (data: any) => {
        toast.warning(data);
      });
    }
    return () => {
      webSocket?.off("rewards");
      webSocket?.off("boostActivated");
      webSocket?.off("boostActivateWarning");
    };
  }, [webSocket]);

  return (
    <>
      {isOpen && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={isOpen ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            <img src="/img/daily_reward.png" />
          </Styled.Icon>
          <Styled.Title>Daily reward</Styled.Title>
          <Styled.Desc>
            Accrue coins for logging into the game daily without skipping
          </Styled.Desc>

          <Days.List>
            {days.map((day, index) => (
              <Days.Item
                key={index}
                className={
                  (user?.dailyRewards || []).find((a) => a.day == day.day)
                    ? "claimed"
                    : ""
                }
              >
                <Days.ItemTitle>Day {day.day}</Days.ItemTitle>
                <Days.ItemImage>
                  <img src="/img/coin.svg" />
                </Days.ItemImage>

                <Days.ItemReward>
                  {formatMoneyDecimal(day.reward)}
                </Days.ItemReward>
              </Days.Item>
            ))}
          </Days.List>

          <Styled.Button onClick={() => addRewardDay()}>Claim</Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>
            Come back tomorrow
          </Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};

const Days = {
  List: styled.div`
    display: flex;
    max-height: 40vh;
    overflow-y: auto;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    margin-bottom: 16px;
  `,
  Item: styled.div`
    padding: 8px;
    height: 83px;
    width: calc((100% / 4) - 7px);
    border-radius: 11px;
    background: #282b30;
    text-align: center;

    &.claimed {
      background: linear-gradient(144.38deg, #c3e736 18.78%, #70bb00 79.54%);
      border: 2px solid #ffffff78;
      box-shadow: 0px 1px 1px 0px #ffffff99 inset;
      img {
        -webkit-filter: drop-shadow(0px 4px 4px 0px #00000040);
        filter: drop-shadow(0px 4px 4px 0px #00000040);
      }
    }
  `,
  ItemTitle: styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  `,
  ItemImage: styled.div`
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
    img {
      display: inline-block;
      height: 24px;
    }
  `,
  ItemReward: styled.div`
    color: #fff;
    font-weight: 700;
    font-size: 14px;
  `,
};
