import React, { useState, useEffect, useRef } from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { useUser } from "../../hooks/useUser";
import { TaskModal } from "./TaskModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../../utils/common";
import dayjs from "dayjs";

export const Tasks = () => {
  const [tasks, setTasks] = useState<any>([]);
  const [otherTasks, setOtherTasks] = useState<any[]>([]);
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isDataLoading, setDataLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  // const checked = useRef(false);

  useEffect(() => {
    if (webSocket) {
      webSocket.emit("getTasks");
      webSocket.emit("getOtherTasks");

      webSocket.on("otherTasks", (d) => {
        setOtherTasks(
          d.map((a: any) => {
            a.isCompleted = (user?.taskFinished || []).some(
              (t) => t.type == a.type
            );
            return a;
          })
        );
      });

      // webSocket.on("commucationTasks", (d) => {
      //   const l = d.map((a: any) => {
      //     a.isCompleted = (user?.taskFinished || []).some(
      //       (t) => t.type == a.type
      //     );
      //     return a;
      //   });
      //   setCommucationTasks(l);

      //   if (checked.current == false) {
      //     l.map((a: any) => {
      //       if (!a.isCompleted) {
      //         handleCheckStatus(a);
      //       }
      //     });
      //     checked.current = false;
      //   }
      // });

      webSocket.on("tasks", (dailyTasks) => {
        setDataLoading(false);
        setTasks(
          dailyTasks.map((a: any) => {
            a.type = "daily";
            a.isCompleted = (user?.taskFinished || []).some(
              (t) =>
                t.type == a.type &&
                a.id &&
                t.date == dayjs().format("YYYY-MM-DD") &&
                t.task === a.id &&
                t.task
            );
            return a;
          })
        );
      });

      webSocket.on("taskStatus", (data) => {
        const { id, type, finished } = data;
        // console.log(id, finished);
        if (type == "daily") {
          setTasks((prevTasks: any) =>
            prevTasks.map((task: any) =>
              task._id === id ? { ...task, completed: finished } : task
            )
          );
        }
        setOtherTasks((prevTasks: any) =>
          prevTasks.map((task: any) =>
            task.type === type ? { ...task, completed: finished } : task
          )
        );
        if (finished) {
          toast.success("Task completed!");
        } else {
          toast.error("Task not completed yet.");
        }
      });
    }
    return () => {
      webSocket?.off("tasks");
      webSocket?.off("taskStatus");
      webSocket?.off("otherTasks");
      webSocket?.off("commucationTasks");
    };
  }, [webSocket, user]);

  const handleTaskClick = (task: any) => {
    setSelectedTask(task);
  };

  const handleModalClose = () => {
    setSelectedTask(null);
  };

  const handleOpenLink = () => {
    handleCheckStatus();
    window.open(selectedTask.activateUrl, "_blank");
  };

  const handleCheckStatus = (task?: any) => {
    const tgUserId = user?.tgId;
    console.log(task);  
    webSocket?.emit(
      "checkTaskStatus",
      JSON.stringify([tgUserId, task || selectedTask])
    );
    setSelectedTask(null);
  };

  // console.log(user);

  return (
    <Styled.Wrap>
      <Styled.Title>Earn more coins</Styled.Title>
      <Styled.Desc>Complete the task and earn more coins!</Styled.Desc>

      <Styled.SubTitle>Daily Tasks</Styled.SubTitle>
      <Styled.List>
        {tasks.map((task: any) => (
          <Styled.Item
            key={task.id}
            onClick={() => {
              if (task.isCompleted) return;
              handleTaskClick(task);
            }}
            style={{ opacity: task.isCompleted ? 0.5 : 1 }}
          >
            <Styled.Avatar src="/img/daily_reward.png" />
            {task.isCompleted && <Styled.Complete>Completed</Styled.Complete>}
            <Styled.Name>{task?.name}</Styled.Name>
            <Styled.Reward>
              <img src="/img/coin.svg" />+{formatMoney(task.rewardAmount)}
            </Styled.Reward>
          </Styled.Item>
        ))}
      </Styled.List>
       
      <Styled.SubTitle>Task list</Styled.SubTitle>
      <Styled.List>
        {otherTasks.map((task, index) => (
          <Styled.Item
            key={index}
            onClick={() => {
              if (task.isCompleted) return;
              handleTaskClick({
                title: task.name,
                ...task,
              });
            }}
            style={{ opacity: task.isCompleted ? 0.5 : 1 }}
          >
            <Styled.Avatar src="/img/daily_reward.png" />
            {task.isCompleted && <Styled.Complete>Completed</Styled.Complete>}
            <Styled.Name>{task.name}</Styled.Name>
            <Styled.Reward>
              <img src="/img/coin.svg" />+{formatMoney(task.rewardAmount)}
            </Styled.Reward>

            <Styled.Reward>
              <img src="/img/dinamod.svg" />+
              {formatMoneyDecimal(task.rewardDiamond)}
            </Styled.Reward>
          </Styled.Item>
        ))}
      </Styled.List>
      <TaskModal
        task={selectedTask}
        onClose={handleModalClose}
        onOpenLink={handleOpenLink}
        onCheckStatus={handleCheckStatus}
      />
    </Styled.Wrap>
  );
};

const Styled = {
  Complete: styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 8px;
    background-color: green;
    color: #fff;
    font-size: 12px;
  `,
  List: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;
  `,
  Item: styled.div`
    text-align: center;
    padding: 16px;
    background-color: #282b30;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Avatar: styled.img`
    width: 60px;
    height: 60px;
    margin-bottom: 16px;
  `,
  Name: styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 8px;
  `,
  Reward: styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    img {
      width: 20px;
      height: 20px;
    }
  `,

  Wrap: styled.div`
    height: 100%;
    overflow: auto;
    background-image: url("/img/bg-2.jpg");
    padding: 16px;
    padding-top: 32px;
    padding-bottom: 100px;
  `,
  Title: styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 48px;
  `,
  Desc: styled.div`
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  `,

  SubTitle: styled.div`
    font-size: 18px;
    font-weight: 500;
    // color: #8d5dfd;
    // text-align: center;
    color: #fff;
    margin-bottom: 16px;
  `,
};
