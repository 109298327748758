import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { useEffect } from "react";
import { useWebSocket } from "../hooks/useWebsocket";
import { toast } from "react-toastify";

interface IProps {
  item: any;
  onClose: Function;
  onDone: Function;
}

export const SheetAddDiamond = ({ item, onClose, onDone }: IProps) => {
  const { webSocket } = useWebSocket();

  useEffect(() => {
    if (webSocket) {
      webSocket.on("buyProductDiamondResult", (data: any) => {
        toast.error(data);
      });
      return () => {
        webSocket.off("buyProductDiamondResult");
      };
    }
  }, [webSocket]);

  return (
    <>
      {item && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={item ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          {item?.type == "x2_coin" && (
            <>
              <Styled.Icon>
                <img src="/img/x2coin.png" />
              </Styled.Icon>
              <Styled.Title>x2 passive COIN for 3 hours</Styled.Title>
              <Styled.SubTitle>(7 times per week)</Styled.SubTitle>
              <Styled.Desc>
                Boosting COIN Efficiency <br />
                Users can use $SPIN to purchase Special Boosters like
              </Styled.Desc>
            </>
          )}

          {item?.type == "12h_off" && (
            <>
              <Styled.Icon>
                <img src="/img/off12h.png" />
              </Styled.Icon>
              <Styled.Title>Increase offline time to 12 hours</Styled.Title>
              <Styled.SubTitle>(7 times per week)</Styled.SubTitle>
              <Styled.Desc>
                Boosting COIN Efficiency <br />
                Users can use $SPIN to purchase Special Boosters like
              </Styled.Desc>
            </>
          )}

          {item?.type == "100_crit" && (
            <>
              <Styled.Icon>
                <img src="/img/critx100.png" />
              </Styled.Icon>
              <Styled.Title>
                20% x100 COIN per <br /> tap for 30 minutes
              </Styled.Title>
              <Styled.SubTitle>(5 times per week)</Styled.SubTitle>
              <Styled.Desc>
                Boosting COIN Efficiency
                <br />
                Users can use $SPIN to purchase Special Boosters like
              </Styled.Desc>
            </>
          )}

          <Styled.Coin>
            <img src="/img/dinamod.svg" />
            {formatMoney(item?.price)}
          </Styled.Coin>

          <Styled.Button onClick={() => onDone()}>Go ahead</Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};

export const Styled = {
  Blur: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
    right: 0;
    bottom: 0;
    background: #00000080;
  `,
  Sheet: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 110;
    text-align: center;

    // display: none;
    transition: all 0.3s;
    transform: translateY(150%);
    opacity: 0;
    &.show {
      opacity: 1;
      transform: translateY(0);
      display: block;
    }
  `,
  Close: styled.div`
    position: absolute;
    top: 24px;
    z-index: 111;
    right: 24px;
    img {
      height: 24px;
    }
  `,
  Content: styled.div`
    position: relative;
    padding: 0 16px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 0px -2px 0px 0px #8515de, 0px -16px 30px 0px #0b167680;

    background: #1c1f24;
    padding-bottom: 16px;
  `,
  Icon: styled.div`
    text-align: center;
    padding: 16px 0;
    img {
      height: 137px;
      display: inline-block;
    }
  `,
  SubTitle: styled.div`
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  `,
  Title: styled.div`
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  `,
  Desc: styled.div`
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 16px;
  `,
  Profit: styled.div`
    align-items: center;
    gap: 4px;
    height: 34px;
    border: 0.3px solid #787878;
    padding: 8px 12px 8px 12px;
    gap: 10px;
    border-radius: 147px;
    display: inline-flex;

    background: #282b30;
    font-size: 12px;

    color: white;

    img {
      height: 13px;
      width: 13px;
    }

    span {
      font-size: 8px;
      line-height: 8px;
      color: #777777;
      font-weight: 500;
    }
  `,

  Coin: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    padding: 16px 0;
    img {
      height: 20px;
    }
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
  `,
  Button: styled.div`
    height: 49px;
    padding: 14px 10px 14px 10px;
    gap: 4px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    font-size: 14px;
    font-weight: 500;
    color: white;
    justify-content: center;
    margin-bottom: 16px;
  `,
  Cancel: styled.div`
    height: 49px;
    padding: 14px 10px 14px 10px;
    gap: 4px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    background: #282b30;
    font-size: 14px;
    font-weight: 500;
    color: white;
    justify-content: center;
  `,
};
