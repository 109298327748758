import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Category, Product, User } from "../../../common/models";
import { Link } from "react-router-dom";

const { adminApiUrl } = getConfig();

const TAKE = 10000;

export const AdminProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [balanceSort, setBalanceSort] = useState<"asc" | "desc">("desc");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const refreshUsers = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/products?skip=${skip}&take=${TAKE}&balanceSort=${balanceSort}`
    );
    const data = await response.json();
    setProducts(data.data);
    setTotal(data.total);
  };

  const refreshCategory = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/categories?skip=${skip}&take=${TAKE}&balanceSort=${balanceSort}`
    );
    const data = await response.json();
    setCategories(data);
  };

  useEffect(() => {
    refreshCategory();
  }, []);

  useEffect(() => {
    refreshUsers();
  }, [page, balanceSort]);

  const onNextClick = () => {
    if (page < Math.ceil(total / TAKE)) {
      setPage(page + 1);
    }
  };

  const onPrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onSortClick = () => {
    if (balanceSort === "asc") {
      setBalanceSort("desc");
    } else {
      setBalanceSort("asc");
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-start justify-between">
        {/* <button
          className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
          onClick={onSortClick}
        >
          Balance sort: {balanceSort === "asc" ? "asc" : "desc"}
        </button> */}

        {/* <div className="flex flex-row items-center space-x-2">
          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onPrevClick}
          >
            Prev
          </button>

          <span className="py-2 px-4 bg-slate-50 font-mono text-sm">
            {(page - 1) * TAKE + 1} - {(page - 1) * TAKE + products.length}
          </span>

          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onNextClick}
          >
            Next
          </button>
        </div> */}
      </div>

      <div className="flex gap-2">
        <Link
          to="/admin/products/add"
          className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono text-center"
        >
          ADD PRODUCT
        </Link>

        <Link
          to="/admin/categories/add"
          className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono text-center"
        >
          ADD CATEGORY
        </Link>
      </div>

      <div className="flex flex-col space-y-2">
        {categories.map((category, index) => (
          <div key={category.id}>
            <div className="flex flex-row items-center bg-slate-50 py-2 px-4  ">
              <Link to={"/admin/categories/" + category.id}>
                <label className="font-bold">{category.name}</label>
              </Link>

              <div style={{ flex: 1 }} />

              {category.active ? (
                <button
                  className="bg-red-600 hover:bg-red-700 text-white font-light py-1 px-4   font-mono"
                  onClick={async () => {
                    await fetch(
                      `${adminApiUrl}/admin/categories/deactivate/${category.id}`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    refreshCategory();
                  }}
                >
                  Deactive
                </button>
              ) : (
                <button
                  className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4   font-mono"
                  onClick={async () => {
                    await fetch(
                      `${adminApiUrl}/admin/categories/activate/${category.id}`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    refreshCategory();
                  }}
                >
                  Active
                </button>
              )}
            </div>
            {products
              .filter((a) => a.category == category.id)
              .map((product, index) => (
                <div
                  className="flex flex-row items-center bg-slate-50 py-2 px-4"
                  key={product.id}
                >
                  <img src={product.icon} className="w-10 h-10 mr-3" />
                  <div>
                    <Link to={"/admin/products/" + product.id}>
                      <label className="font-bold">{product.name}</label>
                    </Link>
                    <p className="font-light text-sm">
                      Price: {product.basePrice} ({product.pricePercentIncrease}
                      %)
                      <br />
                      Profit: {product.baseProfit} (
                      {product.profitPercentIncrease}%)
                      <br />
                      Rank: {product.rankAllow}
                      <br />
                      Max level: {product.maxLevel}
                    </p>
                  </div>
                  <div style={{ flex: 1 }} />

                  {product.active ? (
                    <button
                      className="bg-red-600 hover:bg-red-700 text-white font-light py-0 px-2   font-mono  "
                      onClick={async () => {
                        await fetch(
                          `${adminApiUrl}/admin/products/${product.id}/deactivate`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        );

                        refreshUsers();
                      }}
                    >
                      Deactive
                    </button>
                  ) : (
                    <button
                      className="bg-green-600 hover:bg-green-700 text-white font-light py-0 px-2   font-mono  "
                      onClick={async () => {
                        await fetch(
                          `${adminApiUrl}/admin/products/${product.id}/activate`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        );

                        refreshUsers();
                      }}
                    >
                      Active
                    </button>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
