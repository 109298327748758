import React from "react";
import { Styled } from "../../components/SheetAddDiamond";
import { formatMoney } from "../../utils/common";

export const TaskModal = ({
  task,
  onClose,
  onOpenLink,
  onCheckStatus,
}: any) => {
  if (!task) return null;

  return (
    <>
      {task && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={task ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <br />
          <Styled.Title>{task?.title}</Styled.Title>
          <Styled.Desc>{task?.description}</Styled.Desc>
          <Styled.Coin>
            <img src="/img/coin.svg" />
            {formatMoney(task?.rewardAmount)}
          </Styled.Coin>

          <Styled.Coin>
            <img src="/img/dinamod.svg" />
            {formatMoney(task?.rewardDiamond)}
          </Styled.Coin>
          {task?.activateUrl && (
            <Styled.Button onClick={() => onOpenLink()}>Go ahead</Styled.Button>
          )}
          <Styled.Button onClick={() => onCheckStatus()}>
            Check status
          </Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};
