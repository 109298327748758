// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ##010101;
    height: 100vh;
    font-family: Poppins, Sans-Serif;
  }

  #root{
  height: 100%;
}
`;

export default GlobalStyle;