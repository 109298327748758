import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { Sender, SenderArguments } from '@ton/core';
import {Address} from "@ton/ton";
import { TonConnectUI } from '@tonconnect/ui';

export function useTonConnect(): {
    sender: Sender;
    connected: boolean,
    tonConnectUI: TonConnectUI,
    walletAddress: Address | null;
} {
    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonWallet();
    const walletAddress = wallet?.account?.address ? Address.parse(wallet.account.address) : undefined;
    return {
        sender: {
            send: async (args: SenderArguments) => {
                await tonConnectUI.sendTransaction({
                    messages: [
                        {
                            address: args.to.toString(),
                            amount: args.value.toString(),
                            payload: args.body?.toBoc().toString('base64'),
                        },
                    ],
                    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                });
            },
            address: walletAddress,
        },
        connected: tonConnectUI.connected,
        tonConnectUI: tonConnectUI,
        walletAddress: walletAddress ?? null,
    };
}