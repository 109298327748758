import styled from "styled-components";
import { formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";

interface IProps {
  item: any;
  onClose: Function;
  onDone: Function;
}

export const SheetAddMine = ({ item, onClose, onDone }: IProps) => {
  return (
    <>
      {item && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={item ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            <img src={item?.icon} />
          </Styled.Icon>
          <Styled.Title>{item?.name}</Styled.Title>
          <Styled.Desc>{item?.description}</Styled.Desc>

          <Styled.Profit>
            <span>Profit per hour</span>
            <img src="/img/coin.svg" />+
            {formatMoneyDecimal(item?.nextLevelProfit)}
          </Styled.Profit>

          <Styled.Coin>
            <img src="/img/coin.svg" />
            {formatMoneyDecimal(item?.nextLevelPrice)}
          </Styled.Coin>

          <Styled.Button onClick={() => onDone()}>Go ahead</Styled.Button>
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};
