import React, { useState, useEffect, useMemo } from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { useUser } from "../../hooks/useUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrTrigger, GrGift } from "react-icons/gr";
import styled from "styled-components";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { formatMoney } from "../../utils/common";
import { getConfig } from "../../utils/config";
import { Product, ProductBoost } from "../../common/models";
import { SheetAddMine } from "../../components/SheetAddMine";
import { getSettings } from "../../services/getSettings";
import { SheetAddBoost } from "../../components/SheetAddBoost";

export const Boosts = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();

  useEffect(() => {
    if (webSocket) {
      const handleBoostActivated = (message: string) => {
        toast.success(message);
      };

      webSocket.on("boostActivated", handleBoostActivated);

      webSocket.on("boostBuyResult", (message: string) => {
        toast.error(message);
      });
      return () => {
        webSocket.off("boostActivated", handleBoostActivated);
        webSocket.off("boostBuyResult");
      };
    }
  }, [webSocket]);

  const activateBoost = (boostName: string) => {
    if (webSocket) {
      webSocket.emit("activateBoost", JSON.stringify([user?.tgId, boostName]));
    }
  };

  // const dailyDisabled = useMemo(() => {
  //   if (user) {
  //     return Date.now() - user.lastDailyRewardTimestamp < 1000 * 60 * 60 * 24;
  //   } else {
  //     return true;
  //   }
  // }, [user]);

  // const energyDisabled = useMemo(() => {
  //   if (user) {
  //     return (
  //       Date.now() - user?.lastFullEnergyTimestamp < 1000 * 60 * 60 * 24 &&
  //       user?.fullEnergyActivates >= 3
  //     );
  //   } else {
  //     return true;
  //   }
  // }, [user]);

  const fullEnergyActivates = useMemo(() => {
    if (user) {
      if (Date.now() - user?.lastFullEnergyTimestamp > 1000 * 60 * 60 * 24) {
        return 0;
      }
    }
    return user?.fullEnergyActivates || 0;
  }, [user]);

  const navigate = useNavigate();

  const [products, setProducts] = useState<ProductBoost[]>([]);
  const { adminApiUrl } = getConfig();

  const [setting, setSetting] = useState<any>({});

  useEffect(() => {
    fetch(adminApiUrl + "/all-boost")
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
      });

    getSettings().then((settings) => {
      setSetting(settings);
    });
  }, []);

  const [itemActive, setItemActive] = useState("");

  // console.log("user: ", user, products);

  const currentTabLevel = user?.tapLevel || 0;
  const currentNaxEnergyLevel = user?.maxEnergyLevel || 0;

  const nextLevelTap = (
    products.find((p) => p.type === "tap")?.levels || []
  ).find((l) => l.level === currentTabLevel);

  const nextLevelEnergy = products
    .find((p) => p.type === "energy")
    ?.levels.find((a) => a.level === currentNaxEnergyLevel);

  return (
    <Styled.Wrap>
      <Styled.Header>
        <Styled.Back onClick={() => navigate(-1)}>
          <img src="/img/back.svg" alt="back" />
        </Styled.Back>
        <Styled.Title>Boost</Styled.Title>
        <Styled.Action></Styled.Action>
      </Styled.Header>
      <Styled.HeadLine>Your balance</Styled.HeadLine>
      <Styled.Coin>
        <img src="/img/coin.svg" alt="coin" />
        <span>{formatMoney(user?.balance)}</span>
      </Styled.Coin>
      <Styled.Content>
        <Styled.SubTitle>Free daily boosters</Styled.SubTitle>

        <Styled.Item onClick={() => activateBoost("fullEnergyBoost")}>
          <Styled.ItemIcon>
            <img src="/img/energy.svg" alt="calendar" />
          </Styled.ItemIcon>
          <Styled.ItemContent>
            <Styled.ItemName>Full energy</Styled.ItemName>
            <Styled.ItemDesc>
              {setting?.fullEnergyBoostPerDay - fullEnergyActivates}/
              {setting?.fullEnergyBoostPerDay} available
            </Styled.ItemDesc>
          </Styled.ItemContent>
        </Styled.Item>
      </Styled.Content>
      <Styled.Content>
        <Styled.SubTitle>Boosters</Styled.SubTitle>
        <Styled.Item
          onClick={() => {
            setItemActive("tap");
          }}
        >
          <Styled.ItemIcon>
            <img src="/img/tap.svg" alt="calendar" />
          </Styled.ItemIcon>
          <Styled.ItemContent>
            <Styled.ItemName>MultiTap</Styled.ItemName>
            <Styled.ItemDesc>
              <img src="/img/coin.svg" alt="coin" />
              <span>{formatMoney(nextLevelTap?.price)}</span>
              lvl {(nextLevelTap?.level || 0) + 1}
            </Styled.ItemDesc>
          </Styled.ItemContent>
        </Styled.Item>

        <Styled.Item
          onClick={() => {
            setItemActive("energy");
          }}
        >
          <Styled.ItemIcon>
            <img src="/img/energy.svg" alt="calendar" />
          </Styled.ItemIcon>
          <Styled.ItemContent>
            <Styled.ItemName>Max Enegery</Styled.ItemName>
            <Styled.ItemDesc>
              <img src="/img/coin.svg" alt="coin" />
              <span>{formatMoney(nextLevelEnergy?.price)}</span>
              lvl {(nextLevelEnergy?.level || 0) + 1}
            </Styled.ItemDesc>
          </Styled.ItemContent>
        </Styled.Item>
      </Styled.Content>

      <SheetAddBoost
        type={itemActive}
        price={nextLevelTap?.price || 0}
        profit={nextLevelTap?.profit || 0}
        onClose={() => {
          setItemActive("");
        }}
        onDone={() => {
          if (webSocket) {
            webSocket.emit("buyBoost", {
              tgUserId: user?.tgId,
              type: itemActive,
            });
          }
          setItemActive("");
        }}
      />
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    background-color: #010101;
    height: 100%;
    color: white;
  `,
  Content: styled.div`
    padding: 16px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    align-items: center;
  `,
  Back: styled.div`
    width: 24px;
  `,
  Action: styled.div`
    width: 24px;
  `,
  Title: styled.div`
    font-size: 20px;
    font-weight: 500;
  `,

  HeadLine: styled.div`
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
    color: #777777;
  `,

  Coin: styled.div`
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;

    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;

    margin-bottom: 32px;
    img {
      height: 30px;
      width: 30px;
    }
  `,

  SubTitle: styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  `,

  Item: styled.div`
    background-color: #282b30;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  ItemIcon: styled.div`
    img {
      height: 30px;
    }
  `,
  ItemName: styled.div``,
  ItemContent: styled.div`
    flex: 1;
  `,
  ItemDesc: styled.div`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #777777;
    img {
      height: 20px;
    }
    span {
      font-weight: 600;
      color: white;
    }
  `,
};
