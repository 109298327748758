import { Link } from "react-router-dom";
import styled from "styled-components";

export const EnergyProgress = ({
  energyCount,
  maxEnergyCount,
}: {
  energyCount: number;
  maxEnergyCount: number;
}) => {
  return (
    <Styled.Wrap>
      <Styled.Progress>
        <img src="/img/energy.svg" />
        {energyCount}/{maxEnergyCount}
      </Styled.Progress>
      <Link to="/boost">
        <Styled.Boost>
          <img src="/img/boost.png" />
          Boost
        </Styled.Boost>
      </Link>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    padding-top: 0;
  `,
  Progress: styled.div`
    flex: 1;
    align-items: center;
    display: flex;

    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    font-size: 16px;
    color: white;

    font-weight: 600;
  `,
  Boost: styled.div`
    display: flex;
    font-size: 16px;
    color: white;
    font-weight: 600;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
      margin-right: 10px;
    }
  `,
};
