import { User } from "../common/models";
import { getTelegramUser, webAppVibrate } from "../services/telegramService";
import { usePageLoading } from "./usePageLoading";
import { useSettings } from "./useSettings";
import { useUser } from "./useUser";
import { useWebSocket } from "./useWebsocket";
import { useState, useEffect, useRef, useLayoutEffect } from "react";

interface ClickData {
  user_id: number;
  position: { x: number; y: number };
  time_stamp: number;
  value: number;
}

export const useClick = () => {
  const { webSocket } = useWebSocket();
  const { user, setUser } = useUser();
  const { setPageLoading } = usePageLoading();

  const [clickCount, setClickCount] = useState<number>(0);
  const [energyCount, setEnergyCount] = useState<number>(0);
  const energyIncrementInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (user) {
      setEnergyCount(user.energy);
      setClickCount(user.balance);
    }
  }, [user]);

  const updateCounts = (clicks: number, energy: number) => {
    setClickCount(clicks);
    setEnergyCount(energy);
  };

  const handleClick = (clickData: ClickData) => {
    if (webSocket && energyCount > 0) {
      webSocket.emit("clickEvent", JSON.stringify(clickData));
      if (!setUser) {
        return;
      }

      setUser((prev) => {
        if (!prev) {
          return null;
        }

        if (prev.energy > 0) {
          webAppVibrate();
          const pertime = clickData.value || user?.tapPerTime || 1;
          updateCounts(prev.balance + pertime, prev.energy - 1);
          return {
            ...prev,
            balance: prev.balance + pertime,
            totalBalance: prev.totalBalance + pertime,
            energy: prev.energy - 1,
          };
        } else {
          return prev;
        }
      });
    } else {
      console.error("WebSocket is not connected or no energy left");
    }
  };

  const handleGetUser = (userData: User) => {
    setPageLoading(false);
    updateCounts(userData.balance, userData.energy);
  };

  useLayoutEffect(() => {
    if (user) {
      handleGetUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (energyIncrementInterval.current) {
      clearInterval(energyIncrementInterval.current);
    }

    energyIncrementInterval.current = setInterval(() => {
      if (!setUser) {
        return;
      }

      setUser((prev) => {
        if (!prev) {
          return null;
        }

        // updateCounts(prev.balance + 1, prev.energy - 1);
        return {
          ...prev,
          energy: Math.min(
            prev.energy + (user?.rechargeEnergy || 0),
            user?.maxEnergy || 1000
          ),
        };
      });
    }, 2000);

    return () => {
      if (energyIncrementInterval.current) {
        clearInterval(energyIncrementInterval.current);
      }
    };
  }, []);

  return { handleClick, clickCount, energyCount };
};
