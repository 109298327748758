import React, { useState } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

export const AddTask = () => {
  // const [name, setName] = useState("");

  // const [type, setType] = useState<"telegram" | "link">("telegram");
  // const [activateUrl, setActivateUrl] = useState("");
  // const [rewardAmount, setRewardAmount] = useState(0);

  const [data, setData] = useState({
    name: "",
    rewardAmount: 0,
    link: "",
  });

  const handleSubmit = async () => {
    const response = await fetch(`${adminApiUrl}/admin/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    if (response.ok) {
      setData({
        name: "",
        rewardAmount: 0,
        link: "",
      });
    }
  };

  return (
    <div className="flex flex-col space-y-2 p-2">
      <label>Name</label>
      <input
        type="text"
        placeholder="Task name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setData({ ...data, name: e.target.value })}
        value={data.name}
      />

      {/* <select
        name="choice"
        className="bg-slate-50 py-1 px-3 w-full outline-none"
        onChange={(e) =>
          setType(e.target.value.toString() as "telegram" | "link")
        }
      >
        <option value="join_tele_channel" selected>
          Telegram subscribe
        </option>
        <option value="join_tele_group">Join our TG channel</option>
        <option value="folow_x">Link navigation</option>
        <option value="connect_wallet">Link navigation</option>
        <option value="invite_1">Link navigation</option>
        <option value="invite_3">Link navigation</option>
      </select> */}
      <label>Link</label>
      <input
        type="text"
        placeholder="Link"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setData({ ...data, link: e.target.value })}
        value={data.link}
      />

      {/* <textarea
        placeholder="Description"
        className="bg-slate-50 py-1 px-4 w-full outline-none resize-none h-36"
        onChange={(e) => setDescription(e.target.value)}
      /> */}

      {/* <input
        type="text"
        placeholder="Avatar link"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setAvatarUrl(e.target.value)}
      /> */}

      <label>Amoun</label>
      <input
        type="number"
        placeholder="Reward amount"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) =>
          setData({ ...data, rewardAmount: Number(e.target.value) })
        }
        value={data.rewardAmount}
      />
      {/* <input
        type="number"
        placeholder="Reward diamod amount"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setRewardAmount(Number(e.target.value))}
      /> */}

      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </div>
  );
};
