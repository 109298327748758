import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { format } from "path";

export const Process = () => {
  const nvaigate = useNavigate();
  const { user } = useUser();
  const rank = user?.rank || {};
  return (
    <Styled.Wrap>
      <Styled.Header>
        <Styled.Back onClick={() => nvaigate(-1)}>
          <img src="/img/back.svg" alt="back" />
        </Styled.Back>
        <Styled.Title>Progress</Styled.Title>
        <Styled.Action></Styled.Action>
      </Styled.Header>
      <Styled.Content>
        <Styled.Info>
          <Styled.RankImg>
            <img src="/img/tiger-2.png" alt="coin" />
          </Styled.RankImg>

          <Styled.Rank>{rank?.name}</Styled.Rank>

          <Styled.RankPoint>
            {formatMoneyDecimal(user?.totalBalance)} /{" "}
            {formatMoneyDecimal(rank.max)}
          </Styled.RankPoint>
          <Styled.Proccess>
            <Styled.ProccessInner />
          </Styled.Proccess>
        </Styled.Info>

        {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
          <Styled.Item key={item}>
            <Styled.ItemAvatar>
              <img src="/img/tiger.svg" />
            </Styled.ItemAvatar>
            <Styled.ItemInfo>
              <Styled.ItemName>John Doe</Styled.ItemName>
              <Styled.ItemNote>Joined 2 days ago</Styled.ItemNote>
            </Styled.ItemInfo>
            <Styled.ItemProfit>
              <img src="/img/coin.svg" />
              +5K
            </Styled.ItemProfit>
          </Styled.Item>
        ))} */}
      </Styled.Content>
    </Styled.Wrap>
  );
};

const Styled = {
  RankImg: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    img {
      height: 240px;
    }
  `,
  Rank: styled.div`
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
  `,
  RankPoint: styled.div`
    font-size: 16px;
    text-align: center;
    margin-top: 8px;

    color: #777777;
    margin-bottom: 16px;
  `,
  Proccess: styled.div`
    background: #282b30;
    border-radius: 16px;
    height: 8px;
    margin-top: 8px;
    margin-bottom: 32px;
  `,
  ProccessInner: styled.div`
    background: linear-gradient(
      90deg,
      #98da92 0%,
      #cba2ce 30.2%,
      #c584dd 65.91%,
      #826ee1 100%
    );
    border-radius: 16px;
    height: 8px;
    width: 50%;
  `,

  Info: styled.div`
    // padding: 16px;
    background: radial-gradient(#fab12e, #fab12e22 10%, #fab12e00 70%);
  `,

  Wrap: styled.div`
    background-color: #010101;
    height: 100%;
    color: white;
    overflow: auto;
  `,
  Content: styled.div`
    padding: 16px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    align-items: center;
  `,
  Back: styled.div`
    width: 24px;
  `,
  Action: styled.div`
    width: 24px;
  `,
  Title: styled.div`
    font-size: 20px;
    font-weight: 500;
  `,

  Item: styled.div`
    background: #282b30;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    color: #fff;
    align-items: center;
    gap: 8px;
  `,
  ItemAvatar: styled.div`
    height: 60px;
    width: 60px;
    background: #1e1e1e;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 40px;
    }
  `,
  ItemInfo: styled.div`
    flex: 1;
  `,
  ItemName: styled.div`
    font-size: 18px;
  `,
  ItemNote: styled.div`
    color: #8e8e8e;
    font-size: 12px;
  `,
  ItemProfit: styled.div`
    display: flex;
    font-size: 14px;
    gap: 4px;
    img {
      height: 20px;
      width: 20px;
    }
  `,
};
