import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadInput } from "../UploadInput";

const { adminApiUrl } = getConfig();

export const AddProduct = () => {
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState({
    name: "",
    category: "",
    icon: "",
    type: "mine",
    description: "",
    baseProfit: 0,
    basePrice: 0,
    profitPercentIncrease: 0,
    pricePercentIncrease: 0,

    rankAllow: 0,
    maxLevel: 5,
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [categories, setCategories] = useState<{ id: string; name: string }[]>(
    []
  );

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        const response = await fetch(`${adminApiUrl}/admin/products/${id}`);
        const data = await response.json();
        setData(data);
      };
      fetchProduct();
    }
  }, [id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(`${adminApiUrl}/all-categories`);
      const data = await response.json();
      setCategories(data);
    };
    fetchCategories();
  }, []);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (
      !data.category ||
      !data.name ||
      !data.pricePercentIncrease ||
      !data.profitPercentIncrease ||
      !data.basePrice ||
      !data.baseProfit
    ) {
      toast.error("Please fill all fields");
      return;
    }
    if (id) {
      await fetch(`${adminApiUrl}/admin/products/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });
      navigate(-1);
      return;
    }

    const response = await fetch(`${adminApiUrl}/admin/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);

      setData({
        name: "",
        rankAllow: 0,
        category: "",
        type: "mine",
        icon: "",
        description: "",
        profitPercentIncrease: 0,
        basePrice: 0,
        baseProfit: 0,
        pricePercentIncrease: 0,
        maxLevel: 5,
      });
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <Styled.Wrap>
      <Styled.Label>Product name</Styled.Label>
      <input
        type="text"
        placeholder="Product name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <Styled.Label>Rank allow</Styled.Label>
      <input
        type="text"
        placeholder="Rank allow"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.rankAllow}
        onChange={(e) =>
          setData({ ...data, rankAllow: parseInt(e.target.value) || 0 })
        }
      />

      <Styled.Label>Max level</Styled.Label>
      <input
        type="text"
        placeholder="Rank allow"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.maxLevel}
        onChange={(e) =>
          setData({ ...data, maxLevel: parseInt(e.target.value) || 0 })
        }
      />

      <Styled.Label>Icon link</Styled.Label>
      <input
        type="text"
        placeholder="Product name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.icon}
        onChange={(e) => setData({ ...data, icon: e.target.value })}
      />

      {/* <UploadInput /> */}

      <Styled.Label>Category</Styled.Label>
      <select
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setData({ ...data, category: e.target.value })}
        value={data.category}
      >
        <option value="">Select category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>

      <Styled.Label>Base profit</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.baseProfit}
        onChange={(e) =>
          setData({ ...data, baseProfit: parseInt(e.target.value) || 0 })
        }
      />

      <Styled.Label>Profit percent increase</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.profitPercentIncrease}
        onChange={(e) =>
          setData({
            ...data,
            profitPercentIncrease: parseInt(e.target.value) || 0,
          })
        }
      />

      <Styled.Label>Base price</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.basePrice}
        onChange={(e) =>
          setData({ ...data, basePrice: parseInt(e.target.value) || 0 })
        }
      />

      <Styled.Label>Price percent increase</Styled.Label>
      <input
        type="text"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.pricePercentIncrease}
        onChange={(e) =>
          setData({
            ...data,
            pricePercentIncrease: parseInt(e.target.value) || 0,
          })
        }
      />
      <Styled.Label>Description</Styled.Label>
      <textarea
        placeholder="Description"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />

      <br />
      <br />

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
      >
        CONFIRM
      </button>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Input: styled.input`
    background-color: #222;
    padding: 1rem 4rem;
    padding: 10px;
    width: 100%;
    outline: none;
  `,
  Label: styled.label`
    display: block;
    padding: 10px;
    font-size: 12px;
  `,
  Row: styled.div`
    display: flex;
    gap: 2px;
  `,
  RowItem: styled.div`
    flex: 2;
    &:first-child {
      flex: 1;
    }
  `,
};
