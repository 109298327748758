import styled from "styled-components";
import { formatMoney } from "../utils/common";

export const ScoreCounter = ({ clickCount }: { clickCount: number }) => {
  return (
    <Styled.Wrap>
      <Styled.Icon>
        <img src="./img/coin.svg" />
      </Styled.Icon>

      <Styled.Count>{formatMoney(clickCount)}</Styled.Count>
    </Styled.Wrap>
  );
};

const Styled = {
  Wrap: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  `,
  Icon: styled.div`
    img {
      width: 24px;
      height: 24px;
    }
  `,
  Count: styled.div`
    font-size: 30px;
    color: white;
    font-weight: bold;
    font-family: "Quantico", sans-serif;
  `,
};
