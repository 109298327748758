import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { Styled } from "./SheetAddDiamond";
import { useTonConnect } from "../hooks/useTonConnect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  bundle: any;
  onClose: Function;
  onDone: Function;
}

export const SheetBuyDiamondConfirm = ({ bundle, onClose, onDone }: IProps) => {
  const { connected } = useTonConnect();
  const navigate = useNavigate();
  return (
    <>
      {bundle && <Styled.Blur onClick={() => onClose()} />}
      <Styled.Sheet className={bundle ? "show" : ""}>
        <Styled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </Styled.Close>
        <Styled.Content>
          <Styled.Icon>
            <img src={`/img/bundle${bundle?.level}.png`} />
          </Styled.Icon>
          <Styled.Title>{bundle?.name}</Styled.Title>

          <Styled.Coin>${formatMoney(bundle?.price)}</Styled.Coin>

          {connected ? (
            <Styled.Button onClick={() => onDone()}>
              Buy {formatMoney(bundle?.profit)}$ SPIN
            </Styled.Button>
          ) : (
            <Styled.Button
              onClick={() => {
                toast.warning("Please connect your wallet for buy Diamond");
                navigate("/setting");
              }}
            >
              Buy {formatMoney(bundle?.profit)}$ SPIN
            </Styled.Button>
          )}
          <Styled.Cancel onClick={() => onClose()}>Cancel</Styled.Cancel>
        </Styled.Content>
      </Styled.Sheet>
    </>
  );
};
